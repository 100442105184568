import { Component, OnInit } from '@angular/core';
import { LoaderService } from 'app/service/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  
  constructor(
    private loaderService: LoaderService
    ) { }
  ngOnInit(){
    
    this.loaderService.showLoader();
    
    setTimeout(() => {
      this.loaderService.hideLoader();
    }, 2000);
  }
}
