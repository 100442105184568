import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'app/service/storage.service';


export interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
}

export const ROUTES: RouteInfo[] = [
    { path: '/dashboard',     title: 'Dashboard',         icon:'nc-bank',       class: '' },
    { path: '/unitlist',          title: 'Unit',      icon:'nc-briefcase-24',  class: '' },
    { path: '/productlist',          title: 'Product',      icon:'nc-briefcase-24',  class: '' },
    { path: '/arealist',          title: 'Area',      icon:'nc-briefcase-24',  class: '' },
    { path: '/branchlist',          title: 'Branch',      icon:'nc-briefcase-24',  class: '' },
    { path: '/companylist',          title: 'Company',      icon:'nc-briefcase-24',  class: '' },
    { path: '/purchaselist',          title: 'Purchase',      icon:'nc-bag-16',  class: '' },
    { path: '/saleslist',          title: 'Sales',      icon:'nc-cart-simple',  class: '' },
    { path: '/trans1list',         title:'Dhal mill', icon:'nc-shop',class:''},
    { path: '/trans2list',         title:'Maavu mill', icon:'nc-shop',class:''},
    { path: '/trans3list',         title:'Appalam company', icon:'nc-shop',class:''},
    { path: '/trans4list',         title:'packaging', icon:'nc-shop',class:''},
    { path: '/expenselist',          title: 'Expenses',      icon:'nc-credit-card',  class: '' },
    { path: '/reportlist',          title: 'Reports',      icon:'nc-paper',  class: '' },
    { path: '/user',          title: 'Users',      icon:'nc-single-02',  class: '' },
    { path: '/companylist',          title: 'Settings',      icon:'nc-settings',  class: '' },
];

@Component({
    moduleId: module.id,
    selector: 'sidebar-cmp',
    templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
    
  constructor(
    private storage:StorageService,
    public router:Router){}

    public menuItems: any[];
    ngOnInit() {
        this.menuItems = ROUTES.filter(menuItem => menuItem);
    }
    logout(){
        this.storage.setData('user',{user:[''],token:'',login:false});
        this.router.navigate(['auth/login']);
    }
}
