import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Observable, of, } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL:any="https://muyalappalam.nanoarms.in/api/";
  response:any;
  constructor(private http: HttpClient,private storage:StorageService) { }

  postData(page:any,data:any){
    const headers = { 
       'Accept': 'application/json', 
       'Content-Type': 'application/json', 
       'cache-control': 'no-cache' ,
       'Access-Control-Allow-Origin': '*', 
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers' , 
        'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT' , 
        'Access-Control-Allow-Credentials': 'true' ,
        'Authorization': 'Bearer '+this.storage.getToken(),
      };
    return this.http.post<any>(this.apiURL+page, data, { headers });
  }
  
  getData(page:any){
    const headers = { 
       'Accept': 'application/json', 
       'Content-Type': 'application/json', 
       'cache-control': 'no-cache' ,
       'Access-Control-Allow-Origin': '*', 
        'Access-Control-Allow-Headers': 'Origin, Content-Type, X-Auth-Token, Accept, Authorization, X-Request-With, Access-Control-Request-Method, Access-Control-Request-Headers' , 
        'Access-Control-Allow-Methods': 'GET, POST, DELETE, PUT, OPTIONS, TRACE, PATCH, CONNECT' , 
        'Access-Control-Allow-Credentials': 'true' ,
        'Authorization': 'Bearer '+this.storage.getToken(),
      };
    return this.http.get<any>(this.apiURL+page, { headers });
  }
}
