import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './guard/auth.guard';
export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
   {
    path: '',
    component: AdminLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
  }],
 canActivate:[AuthGuard]
},
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
        },
              ]
    },
    { path: '**', component:  AuthLayoutComponent,
    children: [
        {
      path: 'err404',
      loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
        }
              ] }
]

