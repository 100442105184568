import { Injectable } from '@angular/core'; 
import * as CryptoJS from 'crypto-js'; 

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  encKey:any='n46&^3bnsdjhzxj';
  tokenkey:any='user';
  encdata:any;
  constructor() { }
  getToken(){
    this.encdata = window.localStorage.getItem(this.tokenkey);
    if(this.encdata!==null)
    {
      this.encdata=JSON.parse(CryptoJS.AES.decrypt(this.encdata, this.encKey).toString(CryptoJS.enc.Utf8));
    }
    else
    {
      return "";
    }
    if (this.encdata.login)
    {
      return this.encdata.token;
    }
    else
    {
      return "";
    }
  }
  isLogin(){
    this.encdata = window.localStorage.getItem(this.tokenkey);
    if (this.encdata !== null) {
      this.encdata=JSON.parse(CryptoJS.AES.decrypt(this.encdata, this.encKey).toString(CryptoJS.enc.Utf8));
      if (this.encdata.login)
      {
        return true;
      }
      else
      {
        return false;
      }
    }
    else
    {
      return false;
    }
  }
  setData(key:any,value:any) {
    const jsonData = JSON.stringify(value);
    var encryptedData = CryptoJS.AES.encrypt(jsonData, this.encKey).toString();
    localStorage.setItem(key, encryptedData);
 }
 
 getData(key:any) 
 {
  var encryptedData = window.localStorage.getItem(key);
  if (encryptedData!==null)
  {
    return JSON.parse(CryptoJS.AES.decrypt(encryptedData, this.encKey).toString(CryptoJS.enc.Utf8));
  }
  else
  {
    return null;
  }
 }
 
 removeData(key) 
 {
    localStorage.removeItem(key);
 }
}
