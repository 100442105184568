import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { ToastrModule } from "ngx-toastr";
import { HttpClientModule } from '@angular/common/http';

import { SidebarModule } from 'app/sidebar/sidebar.module';
import { FooterModule } from 'app/shared/footer/footer.module';
import { NavbarModule} from 'app/shared/navbar/navbar.module';
import { FixedPluginModule} from 'app/shared/fixedplugin/fixedplugin.module';
import { AppComponent } from 'app/app.component';
import { AppRoutes } from 'app/app.routing';

import { AdminLayoutComponent } from 'app/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from 'app/layouts/auth-layout/auth-layout.component';


import { SalesinvoiceComponent } from 'app/pages/print/salesinvoice/salesinvoice.component';
import { Err404Component } from 'app/pages/err404/err404.component';
import { LoaderComponent } from 'app/shared/component/loader/loader.component';


import { LoaderService } from 'app/service/loader.service';
import { ApiService } from 'app/service/api.service';
import { StorageService } from 'app/service/storage.service';
import { ToastService } from 'app/service/toast.service';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    SalesinvoiceComponent,
    Err404Component,
    LoaderComponent,
  ],
  entryComponents: [LoaderComponent] ,
  imports: [
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes,{
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    FooterModule,
    FixedPluginModule,
    FormsModule
  ],
  providers: [LoaderService,ApiService,StorageService,ToastService],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
